import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Footer from '../components/Footer'
import Arrow from '../components/Arrow'
import CaseStyles from '../styles/case.sass'

export default class CasesPage extends React.Component {
  render() {
    const { data, pageContext } = this.props
    const { edges: posts } = data.allWordpressPost
    let users = [];

    let stickyObject = posts.filter(function(element){
      return element.node.sticky == true
    }) 
    
    let allCases =  posts.map(({ node: post }) => (
      post.title
    ));

    allCases = allCases.map(item => item.replace("&#038;", "&"))

  
    // data.allSlackUser.edges.map(({ node: user }) => (
    //   users[user.userId] = {
    //     name: user.real_name,
    //     image: user.image_48
    //   }
    // ));
    //users['botuserid'].image = "";
    return (
      <>
        <Layout title={'Cases'} transparent={false}>
          <section className="section">
            <div className="container">
              <div className="content">
                <div className="caseListWrapper">
                  <ul className="caseList">
                  {stickyObject.map(({ node: post }) => (
                    <li className={"caseList-item"} key={post.id}>
                      <a
                        className="caseList-item-link"
                        href={`/case/${post.slug}/`}
                      >
                        <Arrow orientation="right is-transparent" />
                        {post.title.replace("&#038;", "&")}
                      </a>
                    </li>
                  ))}
                  <li className={"caseList-item"}>
                    <a
                      className="caseList-item-link"
                      href={'https://o.adeprimo.se/vr'}
                    >
                      <Arrow orientation="right is-transparent" />
                      Virtual Reality
                    </a>
                  </li>
                  </ul>
                </div>
              </div>
            </div>
            <div><div className={"minorCases marquee"}> {allCases.join(', ')}</div></div>
            <div style={{textAlign: 'center', paddingBottom: '100px'}}>
            </div>
          </section>
        </Layout>
        <Footer />
      </>
    )
  }
}

CasesPage.propTypes = {
  data: PropTypes.shape({
    allWordpressPost: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number,
    numPages: PropTypes.number,
  }),
}

export const pageQuery = graphql`
  query CasesQuery {
    allWordpressPost(
      filter: { title : { ne : "Demo" } }
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          yoast_meta {
            yoast_wpseo_title
            yoast_wpseo_metadesc
            yoast_wpseo_canonical
          }
        }
          ...PostListFields
      }
    }
  }
`
